// To use Html5Qrcode (more info below)
import { Html5Qrcode } from "html5-qrcode";

export const initScanner = () => {
  Html5Qrcode.getCameras()
    .then((devices) => {
      /**
       * devices would be an array of objects of type:
       * { id: "id", label: "label" }
       */
      if (devices && devices.length) {
        var cameraId = devices[0].id;
        // .. use this to start scanning.
      }
    })
    .catch((err) => {
      // handle err
    });
};

const handleQRCodeScanningComplete = (emotions, firstQRCode, secondQRCode) => {
  let firstImgLink = retrieveImgLinkById(emotions, firstQRCode);
  let secondImgLink = retrieveImgLinkById(emotions, secondQRCode);

  return `${firstImgLink} ${secondImgLink} ${firstQRCode} and ${secondQRCode} as an organic material, organic liquid looking shapes, no gravity, floating, matte --no person --style raw`;
};

const retrieveImgLinkById = (emotions, id) => {
  for (let i = 0; i < emotions.length; i++) {
    if (emotions[i].id === id) {
      return emotions[i].img_link;
    }
  }
  return null; // Return null if id is not found
};

export const startScanner = (updateStateCallback) => {
  const html5QrCode = new Html5Qrcode("reader");

  const qrCodeSuccessCallback = (decodedText, decodedResult) => {
    stopScanner(html5QrCode);
    updateStateCallback(decodedText);
    return decodedText;
  };
  const config = { fps: 15, qrbox: { width: 250, height: 250 } };
  html5QrCode.start(
    { facingMode: "environment" },
    config,
    qrCodeSuccessCallback
  );
};

export function stopScanner(scanner) {
  console.log("stopped scanning");
  scanner
    .stop()
    .then((ignore) => {})
    .catch((err) => {
      // Stop failed, handle it.
    });
}
