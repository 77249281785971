import React, { useState, useEffect } from "react";
import "../App.css";
import { initScanner } from "../utils/scanner";
import Scanning from "./scanning";
import Gallery from "./gallery";
import result from "../assets/test_result.png";
import { getAccountInfo } from "../utils/midjourney";

const Landing = () => {
  const [scannerInitialized, setscannerInitialized] = useState(false);
  const [usageInfo, setUsageInfo] = useState(null);

  const handleScan = (phase) => {
    switch (phase) {
      case "init":
        fadeToBlack();
        setTimeout(() => {
          initScanner();
          setscannerInitialized(true);
        }, 400); // Wait for 200ms before continuing
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleUsage();
  }, []);

  useEffect(() => {
    console.log("Usage update");
    if (usageInfo) {
      console.log("Prompt response set to: " + usageInfo);
    }
  }, [usageInfo]);

  const handleUsage = async () => {
    try {
      const response = await getAccountInfo();
      setUsageInfo(response.data.wallet.mj_frozen);
    } catch (error) {
      console.error("Error calling sendPrompt function:", error);
    }
  };

  const fadeToBlack = () => {
    const fadeScreen = document.getElementById("fadeScreen");
    fadeScreen.style.visibility = "visible";
    fadeScreen.style.opacity = 1;
  };

  if (scannerInitialized === false) {
    return (
      <div className="bg">
        <div id="fadeScreen" className="fade-to-black"></div>
        <div className="landing-bg">
          <div className="title-container">
            <h1>
              THIS EMOTION<br></br>DOES NOT EXIST
            </h1>
          </div>
          {usageInfo > 28 ? (
            <div className="action-container">
              <h2 className="interactivity">
                Oops, there's currently<br></br>too many emotions...
                <br></br>
                <br></br>Please try again soon!
              </h2>
              <br></br>
            </div>
          ) : (
            <div
              onClick={(e) => handleScan("init")}
              className="action-container"
            >
              <h2 className="interactivity">Tap to start the experience</h2>
              <br></br>
            </div>
          )}

          <div className="footer-container">
            <h3>
              A project by<br></br>
              <br></br>Andreas Kohler, David Polke, Medea Laim, Vivien Pohls
            </h3>
          </div>
        </div>
      </div>
    );
  } else if (scannerInitialized === true) {
    return (
      <div className="bg">
        <Scanning />
      </div>
    );
  }
};

export default Landing;
