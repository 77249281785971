import React, { useState } from "react";
import { OpenAIAPIKey } from "./keys/config"; // Create a file named 'config.js' with your OpenAI API key

export const getEmotion = async (emotion1, emotion2) => {
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${OpenAIAPIKey}`,
    },
    body: JSON.stringify({
      model: "gpt-4-0125-preview",
      messages: [
        {
          role: "system",
          content:
            "I want you to give me a word which describes two emotions I provide you. You reply to me with only one word which describes the two words I give you. The words you reply with must be real words, they must exist in some dictionary.",
        },
        { role: "user", content: `${emotion1} and ${emotion2}` },
      ],
      max_tokens: 150,
    }),
  });
  const data = await response.json();
  return data;
};
