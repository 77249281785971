import React, { useState, useEffect } from "react";
import "../App.css";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { sendPrompt, retrieveImage, upscaleImage } from "../utils/midjourney";
import { initScanner, startScanner, stopScanner } from "../utils/scanner";
import { getEmotion } from "../utils/chatgpt";

const Generation = ({ emotion }) => {
  const [promptResponseData, setPromptResponseData] = useState(null);
  const [upscaleResponseData, setUpscaleResponseData] = useState(null);

  const [promptResult, setPromptResult] = useState(null);
  const [upscaleResult, setUpscaleResult] = useState(null);

  const [error, setError] = useState(null);
  const [currentJob, setCurrentJob] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isUpscaling, setIsUpscaling] = useState(false);

  const [isFinished, setIsFinished] = useState(null);
  const [emotionName, setEmotionName] = useState(null);

  const handleGPT = async (emotion1, emotion2) => {
    try {
      const response = await getEmotion(emotion1, emotion2);
      setEmotionName(response.choices[0].message.content);
    } catch (error) {
      console.error("Error calling sendPrompt function:", error);
    }
  };

  const handleDownload = () => {
    // Create an anchor element
    const anchor = document.createElement("a");
    anchor.href = isFinished; // Set the href to the image URL
    anchor.download = "My Emotion"; // Set the default file name for the image
    anchor.click(); // Programmatically click the anchor element to trigger the download
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Image",
          url: isFinished,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      console.log("Web Share API not supported");
      // You can provide fallback behavior here for browsers that do not support the Web Share API
    }
  };

  useEffect(() => {
    if (prompt) {
      handlePrompt();
    }
  }, [prompt]);

  useEffect(() => {
    console.log("Prompt update");
    if (promptResponseData) {
      console.log("Prompt response set to: " + promptResponseData.task_id);
      setIsGenerating(true);
      setCurrentJob(promptResponseData.task_id);
      setError(null);
    }
  }, [promptResponseData]);

  useEffect(() => {
    if (upscaleResponseData) {
      console.log("Upscale response set to: " + upscaleResponseData.task_id);
      setIsUpscaling(true);
      setCurrentJob(upscaleResponseData.task_id);
      setError(null);
    }
  }, [upscaleResponseData]);

  useEffect(() => {
    console.log("Critical update");
    if (promptResult) {
      console.log("Result set to: " + promptResult);
      setIsGenerating(false);
      setCurrentJob(promptResult.task_id);
      handleUpscale(promptResult.task_id);
      setError(null);
    }
  }, [promptResult]);

  useEffect(() => {
    if (upscaleResult) {
      console.log(
        "Pipeline completed: " + upscaleResult.task_result.discord_image_url
      );
      addImage(upscaleResult.task_result.discord_image_url, emotionName);
      setIsFinished(upscaleResult.task_result.discord_image_url);
      setIsUpscaling(false);
      setError(null);
    }
  }, [upscaleResult]);

  const handlePrompt = async () => {
    try {
      handleGPT(emotion.emotion1, emotion.emotion2);
      const response = await sendPrompt(emotion.prompt);
      setPromptResponseData(response);
    } catch (error) {
      console.error("Error calling sendPrompt function:", error);
      setError(error.message);
    }
  };

  const handleUpscale = async (task_id) => {
    try {
      const response = await upscaleImage(task_id);
      setUpscaleResponseData(response);
    } catch (error) {
      console.error("Error calling sendPrompt function:", error);
      setError(error.message);
    }
  };

  const handleResult = async () => {
    try {
      const response = await retrieveImage(
        currentJob,
        isGenerating,
        isUpscaling
      );
      if (response) {
        if (response.task_result.discord_image_url && isGenerating) {
          setPromptResult(response);
        } else if (response.task_result.discord_image_url && isUpscaling) {
          setUpscaleResult(response);
        }
        setError(null);
      }
    } catch (error) {
      console.log("Met following error");
      console.log(error);
      setError(error.message);
    }
  };

  const addImage = async (img_link, emotion_name) => {
    try {
      const docRef = await addDoc(collection(db, "generations"), {
        name: emotion_name,
        img_url: img_link,
        date: new Date(),
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (isGenerating || isUpscaling === true) {
        handleResult();
      } else {
        console.log("Nothing to check");
      }
    }, 5000); // Call every 5 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentJob, isGenerating, isUpscaling]);

  if (isFinished !== null) {
    return (
      <div className="image-viewer">
        <img
          className="image-background"
          src={isFinished}
          alt="generated result"
        />
        {emotionName ? <h1>{emotionName}</h1> : null}
        <img className="image-result" src={isFinished} alt="generated result" />
        <button onClick={handleShare}>
          <div className="button-text">Share</div>
        </button>
        <button onClick={handleDownload}>
          <div className="button-text">Download</div>
        </button>
      </div>
    );
  } else {
    return;
  }
};

export default Generation;
