// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBa7WqyQIf8UIZVQTFOWsXkGAt20bA3Slc",
  authDomain: "designs-2def0.firebaseapp.com",
  projectId: "designs-2def0",
  storageBucket: "designs-2def0.appspot.com",
  messagingSenderId: "424346236607",
  appId: "1:424346236607:web:66389511ff4a947fac380a",
  measurementId: "G-JRJ83MT3MZ",
};

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
