import React, { useState, useEffect, useRef } from "react";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig";
import "../App.css";
import QR from "../assets/qr.png";

const Screen = () => {
  const [images, setImages] = useState([]);
  const [latestImage, setLatestImage] = useState(null);
  const previousImagesRef = useRef(null); // Ref for previous images container
  const [scrollDirection, setScrollDirection] = useState("right");

  useEffect(() => {
    const q = query(collection(db, "generations"), orderBy("date", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const imageData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (imageData.length > 0) {
        setImages(imageData.slice(1)); // Exclude latest image from main list
        setLatestImage(imageData[0]); // Set latest image separately
      } else {
        setImages([]);
        setLatestImage(null);
      }

      // Automatically scroll to the end
      if (previousImagesRef.current) {
        previousImagesRef.current.scrollLeft =
          previousImagesRef.current.scrollWidth;
      }
    });

    // Cleanup function to unsubscribe when component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Automatically scroll the container horizontally
    const scrollInterval = setInterval(() => {
      if (previousImagesRef.current) {
        if (scrollDirection === "right") {
          previousImagesRef.current.scrollLeft += 1;
          if (
            previousImagesRef.current.scrollLeft >=
            previousImagesRef.current.scrollWidth -
              previousImagesRef.current.clientWidth
          ) {
            setScrollDirection("left");
          }
        } else {
          previousImagesRef.current.scrollLeft -= 1;
          if (previousImagesRef.current.scrollLeft <= 0) {
            setScrollDirection("right");
          }
        }
      }
    }, 20); // Adjust scroll interval as needed

    return () => clearInterval(scrollInterval); // Cleanup interval on unmount
  }, [scrollDirection]);

  return (
    <div className="screen">
      <div className="screen-content">
        {latestImage && (
          <div>
            <h1>Latest Emotion</h1>
            <div className="latest-image-container">
              <div key={latestImage.id} className="image-item">
                <img
                  className="image latest"
                  src={latestImage.img_url}
                  alt={latestImage.name}
                />
                <p>{latestImage.name}</p>
              </div>
            </div>
          </div>
        )}
        <h1>Previous Emotions</h1>
        <div ref={previousImagesRef} className="previous-image-container">
          {images.map((image) => (
            <div key={image.id} className="image-item">
              <img
                className="image previous"
                src={image.img_url}
                alt={image.name}
              />
              <p>{image.name}</p>
            </div>
          ))}
        </div>
        <img className="qr-code" src={QR} alt="QR code to website" />
      </div>
    </div>
  );
};

export default Screen;
