export const emotions = [
  {
    id: "Confusion",
    img_link: "https://i.imgur.com/9rJ2h3J.png",
  },
  {
    id: "Pain",
    img_link: "https://i.imgur.com/EwyGc9X.png",
  },
  {
    id: "Ache",
    img_link: "https://i.imgur.com/pXCJi9k.png",
  },
  {
    id: "Rage",
    img_link: "https://i.imgur.com/zP6gLf0.png",
  },
  {
    id: "Stress",
    img_link: "https://i.imgur.com/ILK8U9V.png",
  },
  {
    id: "Thrills",
    img_link: "https://i.imgur.com/uHX6BRs.png",
  },
  {
    id: "Fear",
    img_link: "https://i.imgur.com/JlnIMPJ.png",
  },
  {
    id: "Phobia",
    img_link: "https://i.imgur.com/DrQLlJD.png",
  },
  {
    id: "Frightening",
    img_link: "https://i.imgur.com/verQGfG.png",
  },
  {
    id: "Embarrassment",
    img_link: "https://i.imgur.com/cdxQytN.png",
  },
  {
    id: "Gloomy",
    img_link: "https://i.imgur.com/AQ52lPK.png",
  },
  {
    id: "Horror",
    img_link: "https://i.imgur.com/k3NVzgz.png",
  },
  {
    id: "Overthinking",
    img_link: "https://i.imgur.com/2GZqRsH.png",
  },
  {
    id: "Overstimulated",
    img_link: "https://i.imgur.com/Y3gQL81.png",
  },
  {
    id: "Panic",
    img_link: "https://i.imgur.com/HtXiD3a.png",
  },
  {
    id: "Loneliness",
    img_link: "https://i.imgur.com/5QCLLMa.png",
  },
  {
    id: "Heartbreak",
    img_link: "https://i.imgur.com/wmuX5aN.png",
  },
  {
    id: "Confusion",
    img_link: "https://i.imgur.com/fzmkP4s.png",
  },
  {
    id: "Chewy",
    img_link: "https://i.imgur.com/6DfBKr4.png",
  },
  {
    id: "Nostalgia",
    img_link: "https://i.imgur.com/cwWR3be.png",
  },
  {
    id: "Heartache",
    img_link: "https://i.imgur.com/xk2c2xm.png",
  },
  {
    id: "Anticipation",
    img_link: "https://i.imgur.com/fGmuMvW.png",
  },
  {
    id: "Intimidation",
    img_link: "https://i.imgur.com/hyulgRA.png",
  },
  {
    id: "Effortless",
    img_link: "https://i.imgur.com/z40XAHQ.png",
  },
  {
    id: "Overwhelmed",
    img_link: "https://i.imgur.com/RBNLrFA.png",
  },
  {
    id: "Softness",
    img_link: "https://i.imgur.com/iMBLMEl.png",
  },
  {
    id: "Sensual",
    img_link: "https://i.imgur.com/Y5aUBCN.png",
  },
  {
    id: "Shyness",
    img_link: "https://i.imgur.com/uzyRfQl.png",
  },
  {
    id: "Compassion",
    img_link: "https://i.imgur.com/KH6zAXn.png",
  },
  {
    id: "Curage",
    img_link: "https://i.imgur.com/UDzhsRN.png",
  },
  {
    id: "Inquisitiveness",
    img_link: "https://i.imgur.com/kgB9naS.png",
  },
  {
    id: "Butterflies",
    img_link: "https://i.imgur.com/MRBOMMz.png",
  },
  {
    id: "Aura",
    img_link: "https://i.imgur.com/fomhuug.png",
  },
  {
    id: "Trust",
    img_link: "https://i.imgur.com/MYl92A5.png",
  },
  {
    id: "Contentment",
    img_link: "https://i.imgur.com/WaN1Owy.png",
  },
  {
    id: "Curiosity",
    img_link: "https://i.imgur.com/p42xtps.png",
  },
  {
    id: "Pride",
    img_link: "https://i.imgur.com/86qLp8j.png",
  },
  {
    id: "Carefulness",
    img_link: "https://i.imgur.com/lXY7gnc.png",
  },
  {
    id: "Safety",
    img_link: "https://i.imgur.com/mI5I86w.png",
  },
  {
    id: "Love",
    img_link: "https://i.imgur.com/0iM1yqO.png",
  },
  {
    id: "Freakiness",
    img_link: "https://i.imgur.com/L5I3cyc.png",
  },
  {
    id: "Hyperactivity",
    img_link: "https://i.ibb.co/HFT9YXD/Q6kPi8t.png",
  },
  {
    id: "Tingle",
    img_link: "https://i.imgur.com/fgk3jVZ.png",
  },
  {
    id: "Ecstasy",
    img_link: "https://i.imgur.com/T5wDx3j.png",
  },
  {
    id: "Empathy",
    img_link: "https://i.imgur.com/qG4kEsA.png",
  },
  {
    id: "Fluttering",
    img_link: "https://i.imgur.com/lGjTZ9B.png",
  },
  {
    id: "Simmer",
    img_link: "https://i.imgur.com/tJEvCA1.png",
  },
  {
    id: "Enthusiasm",
    img_link: "https://i.imgur.com/bgpHlFq.png",
  },
  {
    id: "Sparkling",
    img_link: "https://i.imgur.com/plEgmiC.png",
  },
  {
    id: "Shiver",
    img_link: "https://i.imgur.com/J5L3E28.png",
  },
  {
    id: "Shame",
    img_link: "https://i.imgur.com/MeeZkI1.png",
  },
  {
    id: "Worrying",
    img_link: "https://i.imgur.com/NApQoHI.png",
  },
  {
    id: "Doubt",
    img_link: "https://i.imgur.com/0aiu8Q3.png",
  },
  {
    id: "Envy",
    img_link: "https://i.imgur.com/S81ECo8.png",
  },
  {
    id: "Anger",
    img_link: "https://i.ibb.co/cTsGHL1/5UXAkPr.png",
  },
  {
    id: "Jealousy",
    img_link: "https://i.imgur.com/Pw3efP2.png",
  },
  {
    id: "Chaos",
    img_link: "https://i.imgur.com/EsKKLOJ.png",
  },
  {
    id: "Scream",
    img_link: "https://i.imgur.com/BnCmrSF.png",
  },
  {
    id: "Ease",
    img_link: "https://i.imgur.com/GurHGt3.png",
  },
  {
    id: "Adrenaline",
    img_link: "https://i.imgur.com/YMUdq6X.png",
  },
];
