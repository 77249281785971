import axios, { isCancel, AxiosError } from "axios";

export const sendPrompt = async (prompt) => {
  console.log("Sending Midjourney request");
  const API_KEY =
    "24e37d4d34f74f263c2ffaf3d91350ff46cd05bb579b432de2a96a712606afe1";

  // Set up request options
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": API_KEY,
    },
    body: JSON.stringify({
      prompt: prompt,
      aspect_ratio: "1:1",
      process_mode: "turbo",
      webhook_endpoint: "",
      webhook_secret: "",
    }),
  };

  try {
    const response = await fetch(
      "https://api.midjourneyapi.xyz/mj/v2/imagine",
      options
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const upscaleImage = async (task_id) => {
  console.log("Sending Midjourney upscale request");
  const API_KEY =
    "24e37d4d34f74f263c2ffaf3d91350ff46cd05bb579b432de2a96a712606afe1";

  // Set up request options
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": API_KEY,
    },
    body: JSON.stringify({
      origin_task_id: task_id,
      index: "1",
      webhook_endpoint: "",
      webhook_secret: "",
    }),
  };

  try {
    const response = await fetch(
      "https://api.midjourneyapi.xyz/mj/v2/upscale",
      options
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const getAccountInfo = async () => {
  console.log("Sending Midjourney upscale request");
  const API_KEY =
    "24e37d4d34f74f263c2ffaf3d91350ff46cd05bb579b432de2a96a712606afe1";

  // Set up request options
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": API_KEY,
    },
  };

  try {
    const response = await fetch(
      "https://api.midjourneyapi.xyz/account/info",
      options
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const retrieveImage = async (task_id) => {
  console.log("Sending Midjourney fetch request");
  // Set up request options
  const options = {
    method: "POST",
    body: JSON.stringify({
      task_id: task_id,
    }),
  };
  try {
    const response = await fetch(
      "https://api.midjourneyapi.xyz/mj/v2/fetch",
      options
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
