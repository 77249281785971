import React, { useState, useEffect } from "react";
import "../App.css";
import { startScanner } from "../utils/scanner";
import Generation from "./generation";
import Gallery from "./gallery";
import { emotions } from "../utils/emotions";
import spinner from "../assets/spinner.gif";

const Scanning = () => {
  const [scanningResult1, setScanningResult1] = useState(null);
  const [scanningResult2, setScanningResult2] = useState(null);
  const [scanningDuplicate, setscanningDuplicate] = useState(null);
  const [scanningInProgress, setScanningInProgress] = useState(null);
  const [prompt, setPrompt] = useState(null);

  const [error, setError] = useState(null);

  const updateScannedCode = (response) => {
    setScanningInProgress(false);
    if (checkEmotion(response) === true) {
      if (!scanningResult1) {
        setScanningResult1(response);
      } else {
        if (response !== scanningResult1) {
          setScanningResult2(response);
        } else {
          setscanningDuplicate(true);
        }
      }
    }
  };

  const checkEmotion = (data) => {
    return emotions.some((item) => item.id === data);
  };

  const retrieveImgLinkById = (id) => {
    for (let i = 0; i < emotions.length; i++) {
      if (emotions[i].id === id) {
        return emotions[i].img_link;
      }
    }
    return null; // Return null if id is not found
  };

  useEffect(() => {
    if (scanningInProgress) {
      console.log("Scanning started");
      startScanner(updateScannedCode);
    }
  }, [scanningInProgress]);

  useEffect(() => {
    console.log("Scanning Update");
    if (scanningResult1) {
      console.log("Scanning first code successful: " + scanningResult1);
      setError(null);
    }
  }, [scanningResult1]);

  useEffect(() => {
    console.log("Scanning Update");
    if (scanningResult2) {
      console.log("Scanning second code successful: " + scanningResult2);
      setError(null);
    }
  }, [scanningResult2]);

  const handleScanning = () => {
    try {
      setscanningDuplicate(false);
      setScanningInProgress(true);
    } catch (error) {
      console.error("Error calling scanning:", error);
      setError(error.message);
    }
  };

  if (scanningInProgress === true) {
    return (
      <div>
        <div className="scanning-container">
          <div id="reader"></div>
        </div>
        <div className="footer-container">
          <h2>Scanning emotion</h2>
        </div>
      </div>
    );
  } else if (scanningDuplicate === true) {
    return (
      <div>
        <div className="title-container"></div>
        <div className="action-container">
          <h2>
            You've already scanned <br></br>rthis emotion
          </h2>
          <button onClick={(e) => handleScanning()}>
            <div className="button-text">Scan again</div>
          </button>
        </div>
        <div className="footer-container"></div>
      </div>
    );
  } else if (scanningResult1 && !scanningResult2) {
    return (
      <div>
        <div className="title-container"></div>
        <div className="action-container">
          <h2>
            Please scan your second <br></br>desired emotion
          </h2>
          <button onClick={(e) => handleScanning()}>
            <div className="button-text">Start Scan</div>
          </button>
        </div>
        <div className="footer-container"></div>
      </div>
    );
  } else if (scanningResult1 && scanningResult2) {
    return (
      <div>
        <Generation
          emotion={{
            prompt: `${retrieveImgLinkById(
              scanningResult1
            )} ${retrieveImgLinkById(
              scanningResult2
            )} ${scanningResult1} and ${scanningResult2} as an organic material, organic liquid looking shapes, no gravity, floating, matte --no person --style raw --turbo`,
            emotion1: scanningResult1,
            emotion2: scanningResult2,
          }}
        ></Generation>
        <div className="title-container">
          <h1>An interesting combination...</h1>
        </div>
        <div className="action-container">
          <div className="selected-images">
            <img
              className="selected-image"
              id="image1"
              src={retrieveImgLinkById(scanningResult1)}
              alt="emotion-1"
            ></img>
            <img
              className="loading-spinner"
              src={spinner}
              alt="loading spinner"
            />
            <img
              className="selected-image"
              id="image2"
              src={retrieveImgLinkById(scanningResult2)}
              alt="emotion-1"
            ></img>
          </div>
          <h2>
            Generating your emotion<br></br>may take up to a minute
          </h2>
        </div>
        <div className="footer-container">
          <Gallery />
        </div>
      </div>
    );
  } else if (!scanningResult1) {
    return (
      <div>
        <div className="title-container"></div>
        <div className="action-container">
          <h2>
            Position your phone over <br></br>a QR code to scan your <br></br>
            desired emotion
          </h2>
          <button onClick={(e) => handleScanning()}>
            <div className="button-text">Start Scan</div>
          </button>
        </div>
        <div className="footer-container"></div>
      </div>
    );
  }
};

export default Scanning;
